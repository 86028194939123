var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "control"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.detailData.h5_content)
    }
  }), _c('div', {
    staticClass: "footer-control"
  }, [_vm.detailData.card_status === 1 ? _c('div', {
    staticClass: "use-time"
  }, [_vm._v(" 权益卡 " + _vm._s(_vm.detailData.can_use_start) + " - " + _vm._s(_vm.detailData.can_use_end) + " ")]) : _vm.detailData.card_status === 2 ? _c('div', {
    staticClass: "use-time"
  }, [_vm._v(" 等待期 " + _vm._s(_vm.detailData.wait_date) + " ")]) : _vm.detailData.card_status === 3 ? _c('div', {
    staticClass: "use-time"
  }, [_vm._v(" 权益卡已过期 ")]) : _vm._e(), _c('div', {
    staticClass: "footer-btn",
    on: {
      "click": _vm.openDetail
    }
  }, [_vm._v(" 立即使用 特药卡权益 ")])]), _c('van-popup', {
    attrs: {
      "closeable": "",
      "round": "",
      "position": "bottom"
    },
    model: {
      value: _vm.kefuShow,
      callback: function ($$v) {
        _vm.kefuShow = $$v;
      },
      expression: "kefuShow"
    }
  }, [_c('div', {
    staticClass: "kefu-control"
  }, [_c('div', {
    staticClass: "kefu-title"
  }, [_vm._v("特药卡权益使用")]), _c('div', [_c('div', {
    staticClass: "info"
  }, [_vm._v("添加专属VIP健康顾问")]), _c('div', {
    staticClass: "info"
  }, [_vm._v("在线1v1查询使用权益")]), _c('div', {
    staticClass: "info"
  }, [_vm._v("长按识别二维码")])]), _c('div', {
    staticClass: "kefu-ewm"
  }, [_c('van-image', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": _vm.ewm
    }
  })], 1)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };