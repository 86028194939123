
import wxShare from "@/wechat/weChatTile";
import qs from "qs";
import {Toast} from "vant";

export default {
    data() {
        return {
            detailData: {},
            ewm: 'https://keyang2.tuzuu.com/uuo/ydj/6519824650ad71678d0519956ffb1dda.png',
            kefuShow: false
        }
    },
    async created() {
        const res = await axios.get('/tyqyk/check_is_activation')
        if (res.data.is_activation != 1) {
            await this.$router.replace({
                path: "/new/ydj",
                query: this.$route.query
            })
        } else {
            const res2 = await axios.get("/tyqyk/get_my_power_h5")
            console.log(res2)
            if (res2.code === 1) {
                this.detailData = res2.data
                if (this.detailData.kf_qrcode) {
                    this.ewm = this.detailData.kf_qrcode
                }
            }
        }

    },
    mounted() {
        let query = qs.stringify(this.$route.query)
        query = query ? `?${query}` : ''
        wxShare({
            title: '一站式健康管理服务平台',
            desc: '康来家园，中国健康管理领导者',
            link: window.location.origin + `/new/ydj${query}`,
        })
    },
    methods: {
        openDetail() {
            if (this.detailData.card_status === 2) {
                Toast("您的权益卡正处于等待期")
            } else if (this.detailData.card_status === 3) {
                Toast("您的权益卡已过期")
            } else if (this.detailData.card_status === 1) {
                this.kefuShow = true
            }
        },
    },
}


